import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaHourglassEnd } from '@react-icons/all-files/fa/FaHourglassEnd';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import { MdLanguage } from '@react-icons/all-files/md/MdLanguage';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Box, Flex, Grid, Stack } from '../../components/Core';
import IconWithText from '../../components/IconWithText';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import InstituteNewsletter from '../../components/Newsletter/InstituteNewsletter';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import StyledLink from '../../components/StyledLink';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import ThemedButton from '../../components/ThemedButton';
import ThemedLink from '../../components/ThemedLink';
import { getLocalizedString } from '../../utils/getLocalizedLink';
import compact from '../../utils/helpers/compact';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';

type Thematiques = 'Mindset' | 'Nutrition' | 'Mouvement' | 'Récupération';

type IFormationTemplate = PageProps<
  Queries.SingleFormationQuery,
  IPageContext
> & {
  data: Queries.SingleFormationQuery & {
    wpFormation: Queries.SingleFormationQuery['wpFormation'] & {
      informationsFormation: Queries.SingleFormationQuery['wpFormation']['informationsFormation'] & {
        thematique: Thematiques;
      };
    };
  };
};

const THEMATIQUES: {
  [thematique in Thematiques]: {
    [language in IPageContext['currentLanguageSlug']]: string;
  };
} = {
  Mindset: {
    en: 'Mindset',
    fr: 'Mindset',
  },
  Mouvement: {
    en: 'Movement',
    fr: 'Mouvement',
  },
  Nutrition: {
    en: 'Nutrition',
    fr: 'Nutrition',
  },
  Récupération: {
    en: 'Recovery',
    fr: 'Récupération',
  },
};

const Formation: FCWithClassName<IFormationTemplate> = (props) => {
  const { data, pageContext } = props;
  const formation = data.wpFormation;
  const { facebookInstitute, instagramInstitute } =
    data.allWpConfiguration.nodes[0].configuration;
  const pageTemplateData =
    'contenuFormation' in data.wpPage.template
      ? data.wpPage.template.contenuFormation
      : null;
  if (!formation || !pageContext.defaultLanguage || !pageTemplateData)
    return null;
  const avatar = serializeImageForImageComponent({
    image: formation.informationsFormation.photo,
  });
  const EURO = '€';
  const TTC = getLocalizedString(
    'TTC',
    'inc. VAT',
    pageContext.currentLanguageSlug,
    pageContext.defaultLanguage
  );

  const serializedNewsletterQuestions = compact(
    pageContext.siteSettings?.newsletterForm?.questions?.map((question) => {
      if (!['first-name', 'last-name', 'email'].includes(question.slug))
        return null;
      const options = question.choixDisponibles?.map((choice) => ({
        checked: choice.checked,
        label: choice.label,
        name: choice.name,
      }));
      return {
        label: question.label,
        name: question.slug,
        options,
        required: question.obligatoire,
        toggleButtonText: question.toggletext,
        type: question.questionType,
      };
    })
  );
  const thematique =
    THEMATIQUES[formation.informationsFormation.thematique]?.[
      pageContext.currentLanguageSlug
    ];
  const isExpired =
    new Date(formation.informationsFormation.dateRaw) < new Date();
  const expiredMessage = pageTemplateData.expiredLabel;
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={formation.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={formation.translations}
      hideNewsletter
      theme="institute"
    >
      <SEO pageData={formation} />
      <Stack className="space-y-gap lg:space-y-gap " justify="center">
        <Box className="bg-lightningYellow pt-8 pb-32 lg:pb-8 px-10 w-[100vw] relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw]">
          {thematique ? (
            <Text className="bg-white text-lightningYellow !uppercase px-2 pt-1 !font-bold w-fit">
              {thematique}
            </Text>
          ) : null}
          <Text variant={TEXT_STYLES.H3} as="h1" className="!uppercase mt-4">
            {formation.title}
          </Text>
          <Flex align="center" className="mt-4">
            <Image image={avatar} className="rounded-full !w-20 !h-20" />
            <Text variant={TEXT_STYLES.H5} className="ml-4 uppercase">
              {formation.informationsFormation.name}
            </Text>
          </Flex>
        </Box>
        <Grid className="grid-cols-1 lg:grid-cols-[3fr_2fr] w-full -mt-6 -lg:mt-8">
          <article className="flex flex-col order-2 w-full max-w-3xl text-lg sm:text-xl lg:order-1">
            <Text className="mx-auto mt-8 lg:mt-0" isProse>
              {formation.informationsFormation.description}
            </Text>
            <Box className="p-4 mt-10 bg-lightningYellow">
              <Text className="mx-auto" isProse>
                {formation.informationsFormation.additionalInfos}
              </Text>
            </Box>
            <StyledLink
              className="self-center mt-16"
              to={pageTemplateData.backToInstituteCtaLink.uri}
            >
              <ThemedButton>
                {pageTemplateData.backToInstituteCtaLabel}
              </ThemedButton>
            </StyledLink>
          </article>
          <Flex
            direction="column"
            className="z-10 order-1 p-6 bg-white -mt-36 lg:-mt-40 lg:order-2"
          >
            <Flex align="center" justify="center">
              <Text
                variant={TEXT_STYLES.H4}
                className="!text-[32px] lg:!text-[42px] pt-1 ml-4"
              >
                {`${formation.informationsFormation.price}`}
              </Text>
              <Text className="pt-1 !text-2xl lg:!text-[32px]">{EURO}</Text>
              <Text
                variant={TEXT_STYLES.H4}
                className="mr-6 ml-2 pt-1 !text-2xl lg:!text-[32px]"
              >
                {TTC}
              </Text>

              {isExpired ? (
                <Text>{expiredMessage}</Text>
              ) : (
                <>
                  {formation.informationsFormation.typeEvent == 'extern' &&
                  formation.informationsFormation.urlExternalEvent ? (
                    <ThemedLink
                      href={formation.informationsFormation.urlExternalEvent}
                      target="_blank"
                    >
                      {pageTemplateData.addCartCtaLabel}
                    </ThemedLink>
                  ) : (
                    <ThemedButton
                      className="snipcart-add-item"
                      data-item-id={formation.slug}
                      data-item-price={formation.informationsFormation.price}
                      data-item-has-taxes-included
                      data-item-url={formation.link}
                      data-item-description={
                        formation.informationsFormation.shortDescription
                      }
                      data-item-image={
                        formation.featuredImage?.node.localFile.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      }
                      data-item-name={formation.title}
                    >
                      {pageTemplateData.addCartCtaLabel}
                    </ThemedButton>
                  )}
                </>
              )}
            </Flex>
            <Stack className="relative w-full mt-6" justify="space-between">
              <IconWithText
                isInline
                icon={FaRegCalendarAlt}
                description={formation.informationsFormation.date}
                className="mb-0"
                iconClassName="w-7 h-7"
                textClassName="font-xl mt-1.5 uppercase"
              />
              <IconWithText
                isInline
                icon={FaHourglassEnd}
                className="mb-0"
                iconClassName="w-7 h-7"
                textClassName="font-xl mt-1.5 uppercase"
                description={formation.informationsFormation.duree.toString()}
              />
              <IconWithText
                isInline
                icon={IoMdPin}
                description={formation.informationsFormation.centre}
                className="mb-0"
                iconClassName="w-7 h-7"
                textClassName="font-xl mt-1.5 uppercase"
              />
              <IconWithText
                isInline
                icon={MdLanguage}
                description={formation.informationsFormation.language}
                className="mb-0"
                iconClassName="w-7 h-7"
                textClassName="font-xl mt-1.5 uppercase"
              />
            </Stack>
          </Flex>
        </Grid>
        <SectionWithTitle
          heading={pageTemplateData.sectionSocial.title}
          text={pageTemplateData.sectionSocial.description}
        >
          <Stack className="mx-auto space-y-8">
            <Flex className="mx-auto">
              <a
                href={facebookInstitute}
                target="_blank"
                rel="noopener noreferrer"
                className="border-b-0 text-text"
              >
                <FaFacebookF className="h-[40px] w-[40px] text-text" />
              </a>
              <a
                href={instagramInstitute}
                target="_blank"
                rel="noopener noreferrer"
                className="border-b-0 text-text"
              >
                <FaInstagram className="h-[40px] w-[40px] text-text" />
              </a>
            </Flex>
            <InstituteNewsletter
              feedbackMessages={{
                error: pageContext.siteSettings.newsletterForm?.errorMsg,
                success: pageContext.siteSettings.newsletterForm?.successMsg,
                update: pageContext.siteSettings.newsletterForm?.updateMsg,
              }}
              languageCode={pageContext.currentLanguage?.code}
              questions={serializedNewsletterQuestions}
              submitButtonLabel={
                pageContext.siteSettings.newsletterForm?.texteBoutonInscription
              }
            />
          </Stack>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default Formation;

export const query = graphql`
  query SingleFormation($id: String, $currentLanguageSlug: String) {
    wpFormation(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      id
      uri
      informationsFormation {
        additionalInfos
        adresse
        centre
        date(formatString: "DD.MM.Y H:mm")
        dateRaw: date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
        description
        duree
        language
        name
        photo {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 150
                height: 150

                layout: CONSTRAINED
              )
            }
            publicURL
          }
        }
        price
        shortDescription
        thematique
        typeEvent
        urlExternalEvent
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Formation" } }
    ) {
      title
      template {
        ... on WpTemplate_PageFormation {
          contenuFormation {
            addCartCtaLabel
            backToInstituteCtaLabel
            detailsLabel
            backToInstituteCtaLink {
              ... on WpPage {
                uri
              }
            }
            expiredLabel
            sectionSocial {
              title
              description
            }
          }
          templateName
        }
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          facebookInstitute
          instagramInstitute
        }
      }
    }
  }
`;
