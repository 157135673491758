/* eslint-disable react/button-has-type */
import parse from 'html-react-parser';
import React, { LinkHTMLAttributes, useContext } from 'react';

import { BUTTON_STYLES, ButtonStyles } from '../../constants/ui';
import ColorContext from '../../context/ColorContext';
import { tw } from '../../utils/tailwind';
import Spinner from '../Spinner/Spinner';

interface IThemedLink extends LinkHTMLAttributes<HTMLAnchorElement> {
  size?: 'sm';
  theme?: 'institute' | 'kine';
  variant?: ButtonStyles;
  isLoading?: boolean;
  href?: string;
  target?: string;
}

const SolidButton = ({ ...props }: IThemedLink) => {
  const { children, className, size, theme, isLoading, href, ...rest } = props;
  const commonClasses =
    'flex border-0 cursor-pointer rounded-none font-bold font-heading uppercase pt-[0.15em] hover:!no-underline px-4 lg:px-6 relative';
  const instituteClasses =
    'bg-primaryInstitute text-white hover:bg-primaryInstitute';
  const kineClasses = 'bg-primaryMain text-text hover:bg-primaryMain';
  const sizeSm = '!h-[45px] lg:!h-[60px] !text-3 lg:!text-[20px]';
  const otherSizes = '!h-[60px] lg:!h-[70px] !text-[20px] lg:!text-5';
  const loadingClass = 'flex items-center justify-center';

  return (
    <a
      href={href}
      className={tw(`${commonClasses}
      ${theme === 'institute' ? `${instituteClasses}` : `${kineClasses}`}
      ${size === 'sm' ? `${sizeSm}` : `${otherSizes}`}
      ${isLoading ? loadingClass : undefined}
      ${className}`)}
      {...rest}
    >
      <span className="flex items-center justify-center w-auto">
        {isLoading ? (
          <Spinner color={theme === 'institute' ? 'white' : 'black'} />
        ) : undefined}
        {parse(children as string)}
      </span>
    </a>
  );
};

const OutlineButton = (props: IThemedLink) => {
  const { children, className, theme, isLoading, href, ...rest } = props;
  const commonClasses =
    '!bg-transparent cursor-pointer uppercase font-heading !font-bold !rounded-none !pt-[0.15em] hover:!bg-transparent hover:!text-primary-400 !h-[60px] lg:!h-[70px] !text-[20px] lg:!text-5 !px-4 lg:!px-6 border border-solid relative whitespace-nowrap';
  const instituteClasses = '!border-primaryInstitute !text-primaryInstitute';
  const kineClasses = '!border-primaryMain !text-primaryMain';
  const loadingClass = 'flex items-center justify-center';
  return (
    <a
      href={href}
      className={tw(`
    ${commonClasses}
      ${theme === 'institute' ? `${instituteClasses}` : `${kineClasses}`}
      ${isLoading ? loadingClass : undefined}  
      ${className}
    `)}
      {...rest}
    >
      <span className="flex items-center justify-center w-auto">
        {isLoading ? (
          <Spinner
            color={theme === 'institute' ? '#781A0C' : '#6dccde'}
            className="pb-1 pr-2.5"
          />
        ) : undefined}
        {parse(children as string)}
      </span>
    </a>
  );
};

const ThemedLink = (props: IThemedLink) => {
  const { theme } = useContext(ColorContext);
  const { className, size, children, variant, ...rest } = props;
  if (!children) return null;
  switch (variant) {
    case BUTTON_STYLES.OUTLINE:
      return (
        <OutlineButton theme={theme} className={className} {...rest}>
          {children}
        </OutlineButton>
      );
    case BUTTON_STYLES.SOLID:
    default:
      return (
        <SolidButton size={size} theme={theme} className={className} {...rest}>
          {children}
        </SolidButton>
      );
  }
};

export default ThemedLink;
